import React from 'react';
import Header from './Header';
import Card from './Card';
import Footer from './Footer';
import './Home.css';

const Home = () => {
  return (
    <div className="Home">
      <Header/>
      <div className="card-container">
          <Card title="CV Card" imageSrc="/cv.png" link="/underConstruction" />
          <Card title="TODO Card" imageSrc="/todo.png" link="/underConstruction" />
          <Card title="Quote Generator Card" imageSrc="/quote.png" link="/underConstruction" />
        </div>
        <Footer/>
    </div>
  );
}

export default Home;
