import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ title, imageSrc, link }) => {
  return (
    <Link to={link}>
        <div className="card">
            <img src={imageSrc} alt={title} />
        </div>
    </Link>
  );
};

export default Card;
