import React from 'react';
import { Link } from 'react-router-dom';
import './UnderConstruction.css';

const UnderConstruction = () => {
  return (
    <div className="container">
      <h1 className="heading">501 - Not Implemented</h1>
      <p className="explanation">This page is under construction and has not been implemented yet. 
      <br/>
      It may be because I haven't had the time. 
      <br/>
      It may be because I have been lazy. 
      <br/>
      It may be because I have been procrastinating. 
      <br/>
      It may be because this was harder than I thought. 
      <br/>
      It may be because I have been busy. 
      <br/>
      It may be because I have been distracted. 
      <br/>
      It may be because I have been working on other things.</p>
    <Link to="/" className='goBack'><p>Thank you for trying. Go Back.</p></Link>
    </div>
  );
};

export default UnderConstruction;
