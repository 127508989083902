// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  const email = 'miguel@xaviersantos.dev';

  return (
    <footer>
      <p>Created by Xavier Santos with ❤️ && 💻</p>
      <a href={`mailto:${email}`} className="email-link" >{email}</a>
    </footer>
  );
};

export default Footer;
