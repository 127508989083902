import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import './Header.css';

const Header = () => {
  
    const [isHovered, setIsHovered] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isTyped, setIsTyped] = useState(false);
    const [typedName, setTypedName] = useState('');
    const [showCursor, setShowCursor] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 900 });
  
    const typingSpeed = 75;
    const animationEnd = 200;
    const sleep = ms => new Promise(r => setTimeout(r, ms));
  
    const nameToType = 'Xavier Santos';
  
    const toggleIsAnimating = () => {
      setIsAnimating(prevIsAnimating => !prevIsAnimating);
    };
  
    const toggleIsTyped = () => {
      setIsTyped(prevIsTyped => !prevIsTyped);
    };
  
  
    useEffect(() => {
      const typeName = async () => {
        setShowCursor(true); 
        toggleIsAnimating();
        for (let i = 0; i <= nameToType.length; i++) {
          setTypedName(nameToType.slice(0, i));
          await sleep(typingSpeed);
        }
        toggleIsTyped();
        toggleIsAnimating();
      };
  
      const eraseName = async () => {
        toggleIsAnimating();
        await sleep(animationEnd);
  
        for (let i = nameToType.length; i >= 0; i--) {
          setTypedName(nameToType.slice(0, i));
          await sleep(typingSpeed);
        }
  
        setShowCursor(false); 
        toggleIsAnimating();
        toggleIsTyped();
      };

      if(isMobile){
        return;
      }
  
      if (isHovered && !isAnimating && !isTyped){
        typeName();
      }
      if(!isHovered && isTyped && !isAnimating){
        eraseName();
      }
  
    }, [isHovered, isAnimating, isTyped, isMobile]);
  
  
    return (
            <div className="header" 
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
            <img src="/logo.png" alt="XS Logo" className="logo" />
            <h1 className={`name ${showCursor ? 'cursor' : ''}`}>{typedName}</h1>
            </div>
    );
};

export default Header;
